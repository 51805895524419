import React from 'react'
import IMG from "./box-gray.png";
import {AiOutlineDelete} from "react-icons/ai"
import "../src/Chekout.css"

const Chekout = () => {
  return (
    <>
     <div className="container mt-2 " >
        <div className="row " style={{display:'flex',justifyContent:'space-between', alignItems:'center',}}>
          <div className="col-lg-7 ">
            <div className="">
             
            </div>
            <div className="">
              <div className="row " style={{border:'2px solid #f0f2f2',borderRadius:'1rem' }}

               >
                <div className="container" style={{height:'2rem'}}>
                <div className="row" style={{border:'2px solid #f0f2f2',borderRadius:'' ,backgroundColor:'#f0f2f2', borderTopLeftRadius:'0.8rem', borderTopRightRadius:'0.8rem', height:'3rem',display:'flex',alignItems:'center', marginBottom:'1rem'}}>
                <div className="col-lg-1">
                  <input type="checkbox"  style={{width:'2rem',fontSize:'2rem', height:'20px',width:'20px'}}/>
                </div>
                <div className="col-lg-11">
                  <span>name</span>
                </div>

                </div>

                </div>
             
                 
                
                <div className="col-lg-1 d-none d-lg-block "  >
                  <div style={{backgroundColor:'#f0f2f2',position:'relative',top:'3rem',width:'66px',height:'66px',textAlign:'center', right: "0.6rem" ,justifyContent:'center',borderRadius:'50%'}}>
                  <img src={IMG} alt=""  width={"30px"} style={{position:'relative',top:"1rem"}} />
                  </div>
               
                </div>
                <div className="col-lg-11 ">
                <table class="table mt-4">
                <thead
                  style={{
                    background: "#f0f2f2",
                    borderBottom: "red",
                    border: "whiite",
                  }}
                >
                  <tr
                    style={{
                      background: "#f0f2f2",
                      borderBottom: "red",
                      border: "whiite",
                    }}
                  >
                    <th scope="col">Quantity</th>
                    <th scope="col">Lead Time</th>
                    <th scope="col">Service</th>
                    <th scope="col">Dispatch Date</th>
                    <th scope="col">order value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  <td> <AiOutlineDelete size={20} color="orange"/> </td>
                  </tr>

                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@fat</td>
                  <td> <AiOutlineDelete size={20} color="orange"/> </td>

                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                    <td>@twitter</td>
                  <td> <AiOutlineDelete size={20} color="orange"/> </td>

                  </tr>
                </tbody>
              </table>
           
                </div>
              </div>
            </div>
              <div className="col">
                <button className="cart-button" >
                  Modify

                </button>

              </div>
          </div>

          {/* <div className=""> */}
          <div class="layout_tabs_mains col-lg-4 mb-5 " style={{border:'2px solid #f0f2f2',borderRadius:'1rem',}}>
            <div className="row">
            <div class="calculated_price_heading" style={{backgroundColor:'#f0f2f2',height:'3rem',borderTopLeftRadius:'0.8rem',borderTopRightRadius:'0.8rem'}}>
                <h5 class=" w-100 text-green fontw-600 mb-0" >Cart Totals</h5>
              </div>
            </div>
              
              <div class="stencil_calculated_price calculated_price_padding">
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{fontWeight:'bold', background:'white'}}>Total Order Value:</label>
                  <label class="price-content" id="cal_Lead_Time"></label>
                </div>
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{fontWeight:'bold'}}>Tax Rate(%)</label>
                  <label id="OrderValue" class="price-content"></label>
                </div>{" "}
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{fontWeight:'bold'}}>Tex Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "} <div class="d-flex justify-content-between">
                  <label class="price-title" style={{fontWeight:'bold'}}>Freight Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "} <div class="d-flex justify-content-between">
                  <label class="price-title" style={{fontWeight:'bold'}}>Net Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "}
               
              </div>
              <div className="col ">
                  <button
                    className="cart-buttons   "
                  >
                    Pay now
                  </button>
                  
                </div>
              <div></div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default Chekout