import openBox from './img/open-box.png'
import checklist from './img/checklist.png'
import unPaid from './img/unpaid.png'
import location from './img/location.png'
import tracking from './img/tracking.png'
import expaction from './img/exception.png'
import user from './img/user.png'
import email from './img/email.png'
import address from './img/address.png'
import { useReducer } from 'react'
import { Link } from 'react-router-dom'
import { Navigate, useNavigate } from "react-router-dom";

function Dashboard() {
  const naviagte = useNavigate();
  var token = localStorage.getItem('token');
  if (token == null || token == undefined) {
    naviagte('/login');
    window.location = "/login";
  }

  return (
    <>
      <section className="Dashboard mt-5 mb-3">
        <div className="container">
          <h3 className="heading">Dashboard</h3>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="main-dash top-50">
                <div className="overview-box">
                  <div>
                    <a href="#">
                      <Link to='/orders'>
                        {/* User Profile   */}
                        <div className="over-box">
                          <div className="over-box-img">
                            <img
                              src={openBox}
                              style={{ width: 45, maxWidth: "fit-content" }}
                            />
                          </div>
                          <div>
                            <h4>My Orders</h4>
                            <p>View, modify, track and manage your orders.</p>
                          </div>
                        </div>
                      </Link>
                    </a>
                  </div>
                  <div>
                    <Link to='/ShippedOrders'>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={checklist}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Shipped Orders</h4>
                          <p>
                            View and download invoices of your shipped orders and
                            place reapeat orders.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to='/unpaid_invoices'>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={unPaid}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Unpaid Invoices</h4>
                          <p>View your unpaid orders and make payment.</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* <div>
                    <Link to='/userProfile'>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={location}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Track Quotation</h4>
                          <p>
                            Check current status of your quotations and procure them.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                  <div>
                    {/* Enquiries */}
                    <Link to='/Enquiries'>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={tracking}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Track Inquiry</h4>
                          <p>Check current status of your inquiries.</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    {/* <a href="/Order_exception"> */}
                    <Link to={"/Order_exception"}>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={expaction}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Exception</h4>
                          <p>
                            View, resolve, approve and upload new files for your
                            exceptions.
                          </p>
                        </div>
                      </div>
                    </Link>
                    {/* </a> */}
                  </div>
                  <div>
                    <Link to={"/userProfile"}>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={user}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>My Profile</h4>
                          <p>
                            Modify and manage login and contact details of your
                            profile for personalized experiences.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* <div>
                    <Link to={"/userProfile"}>
                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={address}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Address Management</h4>
                          <p>Manage, modify or create new ones.</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/EmailManage"}>

                      <div className="over-box">
                        <div className="over-box-img">
                          <img
                            src={email}
                            style={{ width: 45, maxWidth: "fit-content" }}
                          />
                        </div>
                        <div>
                          <h4>Email Management</h4>
                          <p>Manage, modify or create new ones.</p>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Dashboard;