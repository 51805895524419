import React from 'react'

const Loader = () => {
  return (
    <div className='container' style={{display:'flex',justifyContent:'center',height:'70vh',alignItems:'center'}} >
       <div className='spinner-border text-primary' >
        

    </div>  
    </div>
   
  )
}

export default Loader