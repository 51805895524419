// import React from 'react';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';


// import html2pdf from 'html2pdf.js'; 

// const InvoicePdf = () => {
//   const handleDownload = () => {
//     // Get the HTML content of the invoice container
//     const invoiceContainer = document.getElementById('invoice-container');
//     const content = invoiceContainer.innerHTML;

//     // Create a configuration object for html2pdf
//     const options = {
//       margin: 10,
//       filename: 'invoice.pdf',
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     // Use html2pdf to generate the PDF
//     html2pdf().from(content).set(options).outputPdf();
//   };

//   return (
//     <div>
//       <h1>Your Invoice</h1>
//       <div id="invoice-container">
//         {/* Your HTML invoice content goes here */}
//         <p>Date: January 1, 2023</p>
//         <p>Invoice Number: 123456</p>
//         <table>
//           <thead>
//             <tr>
//               <th>Product</th>
//               <th>Description</th>
//               <th>Price</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Product 1</td>
//               <td>Description 1</td>
//               <td>$10</td>
//             </tr>
//             <tr>
//               <td>Product 2</td>
//               <td>Description 2</td>
//               <td>$20</td>
//             </tr>
//             {/* Add more rows as needed */}
//           </tbody>
//         </table>
//       </div>
//       <button onClick={handleDownload}>Download PDF</button>
//     </div>
//   );
// };

// export default InvoicePdf;



import React, { useEffect } from 'react';

const PrintComponent = () => {
  useEffect(() => {
    const handlePrint = () => {
      const divContents = document.getElementById('dvContainer').innerHTML;
      const printWindow = window.open('', '', 'height=400,width=800');
      
      printWindow.document.write('<html><head><title>DIV Contents</title>');
      printWindow.document.write('</head><body >');
      printWindow.document.write(divContents);
      printWindow.document.write('</body></html>');
      
      printWindow.document.close();
      printWindow.print();
    };

    const printButton = document.getElementById('btnPrint');
    printButton.addEventListener('click', handlePrint);

    return () => {
      printButton.removeEventListener('click', handlePrint);
    };
  }, []);

  return (
    <form id="form1">
      <div id="dvContainer">
        This content needs to be printed.
      </div>
      <input type="button" value="Print Div Contents" id="btnPrint" />
    </form>
  );
};

export default PrintComponent;
