

import Layer from "../src/select.png"
import icosn from "../src/icons8-tooltip-24.png"
import "../src/Payment.css"
function Payment() {


  return (
    <>



      <div className="container py-4">
        <h2 className="latter">Checkout</h2>
        <div className="row">
          <div className="col-lg-6">
            <h6 class="form-control" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" >
              Invoice Address

              <img src={Layer} alt="" className="images"
              />

              <img src={icosn} alt="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" />


              {/* <img src={Layer} alt="" style={{width:'20px',position:'relative',left:'19rem'}}   /> */}

            </h6>

            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                <div>

                  <input type="checkbox" />
                  <span>Nikhil</span>
                  <h6>h</h6>
                  <h6>h</h6>
                  <h6>h</h6>
                  <h5>Gst:</h5>
                  <p>$ 48.00</p>


                </div>


              </div>

            </div>
            <div className="row">
              <div className="row">
                <h5>GST for ITC * </h5>

              </div>
              <div className="col-lg-12">
                <button className="btn btn-dark  " id="buttons" style={{ width: "100%" }}>Invoice Address</button>

              </div>
              <div className="row py-3">
                <h5>Payment Method</h5>

              </div>
              <div className="container " id="coll2" style={{ border: '1px solid gray', borderRadius: '0.3rem' }}>
                <div className="row">

                </div>
                <div className="col d-flex h-100 ">
                  <input class="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <h6 className="my-3 mx-1">Credit/Debit Card</h6>

                </div>



              </div>
              <div className="container col  " id='coll1' style={{ border: '1px solid gray', borderRadius: '0.3rem' }}>
                <div className="col d-flex">
                  <input type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                  <h6 className="my-3 mx-1">Paytm</h6>

                </div>



              </div>
              <div className="row py-4">
                <div className="col">
                  <h6>Enter Purchase Order Nr</h6>
                  <input type="text" className="input1" />

                </div>
                <div className="col">
                  <h6>Enter Purchase Order Nr</h6>
                  <input type="text" className="input1" />

                </div>
              </div>

              <div className="row py-2">
                <div className="col-lg-12">
                  <h6>Upload Purchase Order File</h6>

                </div>
                <div className="row mx-2">
                  <div className="col  ">
                    <div className="layout_tabs_main_chile">
                      <div className="">

                        <div id="wrapper" className="dropify-wrapper">
                          <input
                            type="file"
                            name="f-img"
                            className="form-control"
                            id="fileInput"
                          // onChange={handleImageChange}
                          />

                          <div className="dropify-message">
                            <div className="dropify-message-1">
                              <svg
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />

                              </svg>

                              <span>Drag and drop a file here or click</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>

              </div>

            </div>




          </div>


          <div className="col-lg-6">
            <div class=" " style={{ border: '2px solid #f0f2f2', borderRadius: '1rem', }}>
              <div class="calculated_price_heading" style={{ backgroundColor: '#f0f2f2', height: '3rem', borderTopLeftRadius: '0.8rem', borderTopRightRadius: '0.8rem' }}>
                <h5 class=" w-100 text-green fontw-600 mb-0" >Cart Totals</h5>
              </div>

              <div class="stencil_calculated_price calculated_price_padding">
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: 'bold', background: 'white' }}>Total Order Value:</label>
                  <label class="price-content" id="cal_Lead_Time"></label>
                </div>
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: 'bold' }}>Tax Rate(%)</label>
                  <label id="OrderValue" class="price-content"></label>
                </div>{" "}
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: 'bold' }}>Tex Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "} <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: 'bold' }}>Freight Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "} <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: 'bold' }}>Net Price:</label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    2023-09-17
                  </label>
                </div>{" "}

              </div>
              <div className="row">
                <div className="col  py-1" id="bu">
                  <button id="btnsa" >
                    PayNow

                  </button>
                </div>

                <div className="col  py-1" id="paylater">
                  <button id="btnsa" >
                    PayLater

                  </button>

                </div>

              </div>


              <div></div>
            </div>

            <div className="col">



            </div>
          </div>


        </div>

      </div>

    </>


  );

}


export default Payment;