// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom'

const Sidebar = () => {
    // Your sidebar content here
    return (
        <div className="sidebar">
            {/* Sidebar content */}
            <div className="row">
                <div className="col-2">
                    <div className="overview_box py-2">
                        <h3>Overview</h3>
                    </div>
                    <Link to='/dashboard'>
                        <button
                            className="nav-link"
                            id="My_Orders"
                            data-bs-toggle="pill"
                            data-bs-target="#My_Orders-tab"
                            type="button"
                            role="tab"
                            aria-controls="My_Orders"
                            aria-selected="true"
                        >
                            Dashboard
                        </button>
                    </Link>
                    <hr className="border_hr" />
                    <div
                        className="nav flex-column nav-pills overview_box me-3"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                    >
                        <h3 className="mb-0">ORDERS</h3>
                        <Link to='/orders'>
                            <button
                                className="nav-link"
                                id="My_Orders"
                                data-bs-toggle="pill"
                                data-bs-target="#My_Orders-tab"
                                type="button"
                                role="tab"
                                aria-controls="My_Orders"
                                aria-selected="true"
                            >
                                My Orders
                            </button>
                        </Link>
                        
                        
                        <Link to='/ShippedOrders'>
                            <button
                                className="nav-link"
                                id="shipped-Orders-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#shipped-Orders"
                                type="button"
                                role="tab"
                                aria-controls="shipped-Orders"
                                aria-selected="false"
                            >
                                shipped Orders
                            </button>
                        </Link>
                        <Link to='/unpaid_invoices'>
                            <button
                                className="nav-link"
                                id="My_Orders"
                                data-bs-toggle="pill"
                                data-bs-target="#My_Orders-tab"
                                type="button"
                                role="tab"
                                aria-controls="My_Orders"
                                aria-selected="true"
                            >
                                unpaid Invoices
                            </button>
                        </Link>
                        <Link to='/Enquiries'>
                            <button
                                className="nav-link"
                                id="Enquiry-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Enquiry"
                                type="button"
                                role="tab"
                                aria-controls="Enquiry"
                                aria-selected="false"> Track Enquiry </button>
                        </Link>
                        {/* <button
                            className="nav-link"
                            id="v-pills-settings-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-settings"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-settings"
                            aria-selected="false"
                        >
                            Excalation
                        </button> */}
                        <hr className="border_hr " />
                        <h3 className="mb-0">ACCOUNT</h3>
                        <Link to='/userProfile'>
                            <button
                                className="nav-link active"
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                User Profile
                            </button>
                        </Link>
                        {/* <Link to='/edit'>
                            <button
                                className="nav-link"
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                            >
                                Address Management
                            </button>
                        </Link> */}

                        {/* <Link to='/EmailManage'>
                        <button
                            className="nav-link"
                            id="v-pills-messages-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-messages"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-messages"
                            aria-selected="false"
                        >
                            Email Management
                        </button>
                        </Link> */}

                        {/* <hr className="border_hr " />
                        <h3 className="mb-0">TRACKING</h3>
                        <button
                            className="nav-link"
                            id="Track-Inquiry-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#Track-Inquiry"
                            type="button"
                            role="tab"
                            aria-controls="Track-Inquiry"
                            aria-selected="true"
                        >
                            Track Inquiry
                        </button>
                        <button
                            className="nav-link"
                            id="Track-Quotation-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#Track-Quotation"
                            type="button"
                            role="tab"
                            aria-controls="Track-Quotation"
                            aria-selected="false"
                        >
                            Track Quotation
                        </button>
                        <button
                            className="nav-link"
                            id="Email-Management_sec-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#Email-Management_sec"
                            type="button"
                            role="tab"
                            aria-controls="Email-Management_sec"
                            aria-selected="false"
                        >
                            Email Management
                        </button> */}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Sidebar;
