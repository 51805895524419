import React from "react";
import bord from "../../pcb-board.svg";
import layer from "../../layer.svg";
import flex from "../../flexpcb.png";
import circtbord from "../../circuit-board.svg";
import chip from "../../chip.svg";
import { Link } from "react-router-dom";

const ServiceHeader = () => {
  var url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  var active1 = "";
  var active2 = "";
  if (url == "PCB-Layout") {
    active1 = "active";
  } else if (url == "PCB-Fabrication-PCB-Assembly") {
    active2 = "active";
  } else if (url == "PCB-Fabrication") {
    var active3 = "active";
  } else if (url == "PCB-Stencil") {
    var active4 = "active";
  } else if (url == "Component-Sourcing") {
    var active5 = "active";
  } else {
    var active1 = "active";
  }

  return (
    <div id="servicemain">
      <section className="container-fluid py-5 service">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <ul className="nav nav-pills mb-3">
              <li className="nav-item " role="presentation">
                <Link to="/service/PCB-Layout">
                  <button
                    className={`nav-link ${active1}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={bord} />
                      <span>PCB Layout</span>
                    </div>
                  </button>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/service/PCB-Fabrication-PCB-Assembly">
                  <button
                    className={`nav-link ${active2}`}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={bord} />
                      <span> PCB Assembly</span>
                    </div>
                  </button>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/service/PCB-Fabrication">
                  <button
                    className={`nav-link ${active3}`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={flex} />
                      <span>PCB Fabrication</span>
                    </div>
                  </button>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/service/PCB-Stencil">
                  <button
                    className={`nav-link ${active4}`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-pcb"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-pcb"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={chip} />
                      <span>PCB Stencil</span>
                    </div>
                  </button>
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link to="/service/Component-Sourcing">
                  <button
                    className={`nav-link ${active5}`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-pcs"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-pcs"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={circtbord} />
                      <span>Component Sourcing</span>
                    </div>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceHeader;
